// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase configuration för att köra mot Live-miljön
const firebaseConfig = {
  apiKey: "AIzaSyDQk8cjyEV9JLSd5cv4j1-iQ4sD4G8aP9w",
  authDomain: "gpsorienteering-db7f5.firebaseapp.com",
  databaseURL: "https://gpsorienteering-db7f5.firebaseio.com",
  projectId: "gpsorienteering-db7f5",
  storageBucket: "gpsorienteering-db7f5.appspot.com",
  messagingSenderId: "90522584091",
  appId: "1:90522584091:web:126c50afd586c792"
};

// Konfiguration för att köra mot GpsODev
/*const firebaseConfig = {
  apiKey: "AIzaSyA_dyEB51MiImwaFZm0KWHGzV9zJ-MCFjU",
  authDomain: "gpsodev-c69f3.firebaseapp.com",
  databaseURL: "https://gpsodev-c69f3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gpsodev-c69f3",
  storageBucket: "gpsodev-c69f3.appspot.com",
  messagingSenderId: "452599648116",
  appId: "1:452599648116:web:b5c5ba6457bce931052621",
  measurementId: "G-G26QSFSSKX"
};*/
const fbapp = initializeApp(firebaseConfig);
const functions = getFunctions(fbapp, "europe-west1");

export const getCourseAndResult = httpsCallable(functions, 'getCourseAndResult');
export const reparseCourse = httpsCallable(functions, 'reparseCourse');
export const addAnalytics = httpsCallable(functions, 'addAnalyticsEvent');