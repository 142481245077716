<template>
    <div class="tabs">
      <router-link class="tab" :class="{ 'selected-tab': $route.name === 'app-features-comparison' }" to="/features/comparison">Feature Comparison</router-link>
      <router-link class="tab" :class="{ 'selected-tab': $route.name === 'app-features-maps' }" to="/features/maps">Maps</router-link>
      <router-link class="tab" :class="{ 'selected-tab': $route.name === 'app-features-courses' }" to="/features/courses">Courses</router-link>
      <router-link class="tab" :class="{ 'selected-tab': $route.name === 'app-features-run-course' }" to="/features/run-course">Run Course</router-link>
      <router-link class="tab" :class="{ 'selected-tab': $route.name === 'app-features-results' }" to="/features/results">Results</router-link>
      <router-link class="tab" :class="{ 'selected-tab': $route.name === 'app-features-settings' }" to="/features/settings">Settings</router-link>
    </div>
    <router-view class="content" />
</template>

<script>
export default {
  name: 'View-AppFeatures'
}
</script>

<style scoped>
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: whitesmoke;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  position: sticky;
  margin-top: -8px;
}

.tab {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.tab:hover {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: var(--primary-color);
}

.selected-tab {
  color: var(--primary-color);
  text-decoration: none;
}


  /* Desktop View */
  .desktop-view .tabs {
    top: 60px; 
    height: 48px;
  }
  .mobile-view .tab {
    font-size: 16px;
  }
  /* Mobile View */
  .mobile-view .tabs{
    top: 40px; 
    height: 36px;
  }

  .mobile-view .tab {
    font-size: 10px;
  }
</style>

