<template>
  <div>
    <h1>Settings</h1>
    <p>Under 'Settings' in the main menu you can set the following. Note that some settings are only available in the Premium version of GPS Orienteering.</p>
    <ul>
      <li><strong>Disable auto-start</strong> - the app punches automatically when you reach the control points. This can be inconvenient for the start point, for example at a training event, when you stand at the starting point and wait for the start signal. By disabling the auto-start you have to punch manually at the start point and in this way decide when the race shall start.</li>
      <li><strong>LiveTrack on start</strong> - automatically activates LiveTrack when you start a race.</li>
      <li><strong>Keep display on</strong> - prevents the display from being automatically turned off during a race.</li>
      <li><strong>Show code</strong> - show or hide the control codes on the map. </li>
      <li><strong>Show value</strong> - show or hide the value of each control point on the map (rogaining). </li>
      <li><strong>Gesture punching</strong> - the app supports manual punching by turning the phone back and forth. If you have problem with unintentional punching due to gestures, then you can disable gesture punching.</li>
      <li><strong>Compass</strong> - the app can show a compass on the map during a race. Remember to calibrate the compass by making the 'figure 8' gesture, see image below.</li>
      <li><strong>Vibrate</strong> - the mobile can give you a haptic feedback by vibrating when a control point has been punched.</li>
      <li><span style="color: #000000;"><b>Voice assistance </b>(only in Premium version)<b></b></span>- use voice messages during the race. You will get a voice message when you punch (e.g 'Punched at 4') and remaining time every 5th minute at rogaining races (e.g. 'Remaining time 35 minutes').</li>
    </ul>
    <img class="img-compass-calibrate" alt="" src="../assets/compass_calibrate.png" />
    <p><em>Make a figure 8 gesture to calibrate the compass</em></p> 

  </div>
</template>

<script>
export default {
  name: 'View-AppFeaturesSettings'
}
</script>

<style scoped>

  /* Mobile View */
  .mobile-view .img-compass-calibrate {
    width: 60%;
  }
</style>