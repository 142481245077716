<template>
  <div class="content">
    <h1>Privacy Policy</h1>
    <p><strong>GPS Orienteering</strong></p>
    <p>GPS Orienteering is a location-based orienteering system provided by <a title="https://vidaviewtech.com/" href="https://vidaviewtech.com/">Vidaview Tech AB</a> (the Company). The system uses the geographical location of the user’s device (smartphone). It also contains a user management system to give the user control over the recorded and stored location data.</p>

    <p>The system consists of apps for Android (GPS Orienteering and GPS OrienteeringRun) and iPhone (GPS Orienteering), a web frontend and a backend service for storing and processing data. Google Firebase is used as cloud service for user management and data storage.</p>

    <p>The user privacy is important to us and we have implemented a number of functions to balance the desire to share event results with others and to provide control over the personal location data.</p>

    <span style="text-decoration: underline;">The following personal data is handled by the GPS Orienteering system:</span>
    <p>User data:</p>
    <ul>
      <li>Every user of the app needs to enter a <em>name</em> that is used for presentation in result lists etc. The name can be the full name (first name and surname), a nickname or similar.</li>
      <li><em>Email</em> and <em>password</em> is used to identify a <em>registered user</em>.</li>
      <li>If a user participates in an event race without being a registered user the user will be a so called <em>guest user</em>.</li>
    </ul>

    <p>Location data:</p>
    <ul>
      <li>The location of the user's device is used in a background service during an event race. The location is used to determine if the user is visiting control points (so called punching) and the location is continuously recorded to later on be able to show which path the user has selected to follow to find the different control points.</li>
      <li>For guest users and registered users the event result will be stored locally in the mobile device. The event result contains location data and consists of the control points visited and the path taken between the control points.</li>
      <li>The event result is also stored in the cloud storage. Each user, both registered and guest user, has its own private storage for event results that only the user (and the Company) can access.</li>
      <li>Both registered users and guest users can publish their event result to make it accessible by other users. The user can select to publish only the control points visited or the complete path. The event results for one event course is protected by a unique course id. Other users can access the published data if he/she knows the course id.</li>
    </ul>

    <span style="text-decoration: underline;">Deletion of data:</span>
    <p>Both registered and guest users can do the following:</p>
    <ul>
      <li>As a user you can revoke your published event result whenever you want. After revocation of an event result the location data will no longer be accessible by other users.</li>
      <li>As a user you can also permanently delete your event result, both from the mobile device and the private part of the cloud storage.</li>
      <li>As a user you can at any time delete your account which will permanently delete all your data (user data and location data) both locally on the device (smartphone) and in the cloud storage.</li>
    </ul>
    <span style="text-decoration: underline;">Sharing of data:</span>
    <p>We do not use any personal data (e.g. location data or email addresses) for any other purpose than for functionaly within the GPS Orienteering system. We do not sell any personal data to other companies.</p>

    <strong>Orienteering Map Notes</strong>
    <p>'Orienteering Map Notes' ('Kartnoteringar Orientering') is another of our products, completely separated from GPS Orienteering. It is an Android app to support the work of making revisions and updates of orienteering maps. This app does not store or transfer any data to backend services.</p>

    <strong>Update of the Privacy Policy</strong>
    <p>The services we offer may change from time to time. As a result, at times it may be necessary for us to make changes to this Privacy Policy. Accordingly, we reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any personal data.</p>

    <p>Last updated April 3, 2024</p>
  </div>
</template>

<script>
export default {
  name: 'View-PrivacyPolicy'
}
</script>