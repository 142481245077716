<template>
  <div class="content">
    <h1>Support</h1>
    <p><b>Have Questions or Feedback?</b></p>
    <p>If you have any questions, comments, or feedback, please don't hesitate to reach out to us! We're here to help and would love to hear from you.</p>
    <p>E-mail: <a href="mailto:support@gpso.se">support@gpso.se</a></p>
    <br>
    <hr width='100%'>
    <p><b>Submit a Support Request</b></p>
    <p>To help us resolve your issue more efficiently, please provide the following details when submitting your support request:</p>
    <ul>
      <li><b>Mobile platform:</b> Indicate whether you're using <b>iOS</b> or <b>Android</b>.</li>
      <li><b>App version:</b> Mention the version of the app you're currently using. You can find this information in the app's settings (iOS) or help (Android) page.</li>
    </ul>
    <p>Providing this information upfront allows us to address your request more quickly and accurately.</p>
    <p>Thank you for your cooperation!</p>
    <hr width='100%'>
    <br>
    <p>GPS Orienteering is provided by <a title="https://vidaviewtech.com/" href="https://vidaviewtech.com/">Vidaview Tech AB</a>.</p>

  </div>
</template>

<script>
export default {
  name: 'View-Support'
}
</script>