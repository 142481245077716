<template>
  <div>
    <h1>Results</h1>
    <p><strong>Course result table</strong></p>

    <p>You can see the course results either on the web (<a title="https://gpso.se/" href="https://gpso.se/">https://gpso.se/</a>) or in the app.</p>
    <ol>
      <li>Open the course and select 'View results'.</li>
      <li>Your latest run is shown at the top and a table with all your results and all published results is shown below.</li>
      <ul>
        <li>The results are shown in a table with the best result first.</li>
        <li>'DSQ' means disqualified. The result contains a 'forced punch'.</li>
        <li>'DNF' means 'Did not finish'. The race was aborted.</li>
        <li>'S' means that orienteering support was used</li>
        <li>'M' means that one or more control points were punched manually</li>
        <li>'F' means that one or more control points were 'forced punch', i.e. a manuel punch at a distance greater than 50 meters from the control point.</li>
      </ul>
      <li>Click on a result to see leg times, route etc. for an individual result</li>
    </ol>
    <strong>Individual result</strong>

    <p>You can evaluate the race by the help of a result table and a result profile (Android). In the Premium version you can also view the track on the map, replay the race and compare the result with other participants.</p>

    <span style="text-decoration: underline;">Result table</span>

    <p>In the result table you see your time for each leg, the accumulated time, leg length, track length (the distance you actually ran), difference in distance (that is how much longer your ran compared to the leg length) and pace (minutes:seconds per kilometer).</p>

    <img class="img-result-table" alt="" src="../assets/result_table.png" />
    <p>Figure 1: Result table</p>
    <p><span style="text-decoration: underline;">Track on map (Premium version only)</span></p>
    <p>You can also see the track on the map. The route is coloured green, yellow, orange or red depending on the current speed. Green means high speed and red is low speed. Under 'Settings' you can select if you want to colourcode the track, select the limits for green and red speed and the line width. The blue dots show where the punch was done. The punch is mostly not on the exact position of the control point, and the reason is that the punch is done when you are close enough.</p>
    <p>An 'M' in the blue dots means that you have punched manually.</p>
    <img class="img-result-track-color" alt="" src="../assets/result_track_color.jpg" />
    <p>Figure 2: Parts of the course (magenta) and your track (green/yellow/orange/red).</p>

    <img class="img-result-track-settings" alt="" src="../assets/result_track_settings.jpg"/>
    <p>Figure 3: Track settings</p>

    <img class="img-result-track-signs" alt="" src="../assets/result_track_signs.jpg" />
    <p>Figure 4: Info-signs showing legtime, length, pace etc.</p>

    <span style="text-decoration: underline;">Replay race (Premium version only)</span>

    <p>You can replay the race. Select which opponents to include in the replay.</p>

    <img class="img-result-replay" alt="" src="../assets/result_replay.png" />
    <p>Figure 5: Replay your race and compare with an opponent.</p>
    <p><span style="text-decoration: underline;">Profile diagram</span></p>
    <p>There is also a profile diagram with the control points along the time axis. The diagram shows your altitude (blue), speed (yellow), average speed between two control points (green) and accumulated length (orange). You can freely choose which curves you want to show in the profile diagram.</p>

    <img class="img-result-profile" alt="" src="../assets/result_profile.jpg" />
    <p>Figure 7: Result profile showing height (blue), speed (yellow), mean speed between two control points (green) and accumulated length (orange).</p>
  </div>
</template>

<script>
export default {
  name: 'View-AppFeaturesResults'
}
</script>

<style scoped>

  /* Mobile View */
  .mobile-view .img-result-table {
    width: 100%;
  }
  .mobile-view .img-result-track-color {
    width: 100%;
  }
  .mobile-view .img-result-track-settings {
    width: 100%;
  }
  .mobile-view .img-result-track-signs {
    width: 100%;
  }
  .mobile-view .img-result-replay {
    width: 100%;
  }
  .mobile-view .img-result-profile {
    width: 100%;
  }
</style>