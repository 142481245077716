import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import GettingStarted from './views/GettingStarted.vue'
import AboutUs from './views/AboutUs.vue'
import AppFeatures from './views/AppFeatures.vue'
import AppFeaturesMaps from './views/AppFeaturesMaps.vue'
import AppFeaturesCourses from './views/AppFeaturesCourses.vue'
import AppFeaturesRunCourse from './views/AppFeaturesRunCourse.vue'
import AppFeaturesResults from './views/AppFeaturesResults.vue'
import AppFeaturesSettings from './views/AppFeaturesSettings.vue'
import AppFeaturesComparison from './views/AppFeaturesComparison.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import Support from './views/Support.vue'
import QRCodeControl from './views/QRCodeControl.vue'
import Redirect_uri from './views/Redirect_uri.vue'
import CourseView from './views/CourseView.vue'
import Vidablick from './views/Vidablick.vue'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/getting-started',
    component: GettingStarted,
  },
  {
    path: '/features',
    component: AppFeatures,
    children: [
      {
        path: 'maps',
        name: 'app-features-maps',
        component: AppFeaturesMaps
      },
      {
        path: 'courses',
        name: 'app-features-courses',
        component: AppFeaturesCourses
      },
      {
        path: 'results',
        name: 'app-features-results',
        component: AppFeaturesResults
      },
      {
        path: 'run-course',
        name: 'app-features-run-course',
        component: AppFeaturesRunCourse
      },
      {
        path: 'settings',
        name: 'app-features-settings',
        component: AppFeaturesSettings
      },
      {
        path: 'comparison',
        name: 'app-features-comparison',
        component: AppFeaturesComparison,
      },
      // Route for empty path /features
      {
        path: '',
        redirect: '/features/comparison'
      },
      // Catch-all route for child paths under /features
      {
        path: '/features/:catchAll(.*)',
        redirect: '/features/comparison'
      }
    ]
  },
  {
    path: '/course',
    name: 'Course',
    component: CourseView,
  },
  {
  path: '/support',
    component: Support,
  },
  {
    path: '/redirect_uri',
      component: Redirect_uri,
    },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/about',
    component: AboutUs,
  },
  {
    path: '/vidablick',
    component: Vidablick,
  },
  {
    path: '/controlpoint',
    component: QRCodeControl,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
    }
})

export default router