<template>
  <div class="view-course">
    <h1>View Course</h1>
    <input type="text" maxlength="8" v-model="courseId" @keyup.enter="navigateToCourse" placeholder="Enter 8 character course code..." />
    <button class="view-button" @click="navigateToCourse">View</button>
    <div v-if="showErrorMessage" class="error-message">
      8 characters are required. Only letters and digits.
    </div>
    <small>Enter code 'szheh9ic' to view an example course</small>
  </div>
</template>
  
<script>

  export default {
    name: "Home-ViewCourse",
    data() {
        return {
            courseId: "",
            showErrorMessage: false,
        };
    },
    methods: {
        navigateToCourse() {
            var letterNumber = /^[0-9a-zA-Z]+$/;
            if (this.courseId.length !== 8 || !this.courseId.match(letterNumber)) {
                // show error message if courseId is not 8 characters long or contains non-valid characters
                this.showErrorMessage = true;
            }
            else {
                // navigate to the Course route if courseId is 8 characters long
                this.$router.push({
                    name: "Course",
                    query: {
                        courseid: this.courseId,
                    },
                });
            }
        },
    },
}
</script>

<style scoped>
  .view-course h1{
    font-size: 24px;
    font-weight: bold;
    margin: 5px;
  }

  .view-course small {
    display: block;
  }

  .view-course input[type="text"] {
    font-weight: bold;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    padding: 8px;
    transition: all 0.1s ease-in-out;
  }

  .view-course input[type=text]:focus {
    border: 2px solid #555;
  }

  button.view-button {
    /* add some basic styles for the button */
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    /* add some custom styles for the button */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin: 0 10px;
  }

  button.view-button:hover {
    opacity: 0.9;
  }

  .error-message {
    /* add some basic styles for the error message */
    color: red;
    font-size: 12px;
  }
</style>