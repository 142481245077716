import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import router_vidaview from './routes_vidaview'

const app = createApp(App)

const hosturl = window.location.hostname;

let routes;
if (hosturl === 'vidaviewtech.com')
{
    routes = router_vidaview;
}
else
{
    routes = router;
}

app.use(routes)

app.mount('#app')