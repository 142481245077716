<template>
    <div class="intro-text">
        <h3>Get ready to revolutionize your orienteering experience with GPS Orienteering!</h3>
        <p>GPS Orienteering allows you to participate in orienteering events without the need for physical control points. Using the GPS location service on your phone (or Apple Watch), the app automatically punches virtual control points as you navigate through the course.</p>
        <button class="get-started-button"  @click="handleGetStartedButtonClick">Get Started</button>
    </div>
</template>

<script>
  export default {
    name: "Home-IntroText",
    methods: {
        handleGetStartedButtonClick() {
            this.$router.push("/getting-started");
        },
    },
}
</script>

<style scoped>
  .intro-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    text-align: center;
  }

  .intro-text h3{
    margin-bottom: 0;
    max-width: 650px;
  }

  .get-started-button {
    font-size: 24px;
    font-weight: bold;
    color: white;
    background-color: var(--primary-color);
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
    padding: 16px 32px;
    margin-top: 20px;
    margin-bottom: 50px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .get-started-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
</style>
