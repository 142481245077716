<template>
  <div class="content">
    <h1>Vidablick poängorientering</h1>
    <p>Arrangemanget är slut för 2023.</p>
    <br>
    <p>TACK FÖR DENNA GÅNGEN!</p>
  </div>
</template>

<script>
export default {
  name: 'View-Vidablick'
}
</script>