<template>
  <div class="content">
    <h1>About us</h1>
  </div>
</template>

<script>
export default {
  name: 'View-AboutUs'
}
</script>